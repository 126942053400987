@import "../../global-styles/common.scss";

.wrapper, .wrapperDark {
  width: 100%;
  min-height: 40vh;

  .banner {
    width: 100%;
    height: 30vh;
    background-image: url("../../assets/images/reservation-page/buick-classic-cars-3431909_640.jpg");
    background-size: cover;
    background-position: center;

    h1 {
      background-color: rgba(0, 0, 0, 0.5);
      text-align: center;
      color: $greyLight;
      text-transform: uppercase;
    }
  }

  .infoConetnt {
    width: 90%;
    margin: 20px auto;

    .content1,
    .content2 {
      margin-bottom: 30px;

      h3 {
        color: $blueNormal;
        margin-bottom: 10px;
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        li {
          font-size: $sizeXXS;
          margin-bottom: 10px;
          margin-left: 20px;
        }
      }

      p {
        font-size: $sizeXXS;
      }

      .aButton {
        margin-bottom: 10px;
        border: 1px solid $greyDarness;
        border-radius: $radius;
        padding: 10px;
        color: $greyLight;
        font-weight: 700;
        font-size: $sizeXXS;
        background-color: $greyDarness;
        transition: $transition;
      }
    }
  }

  .cars {
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .car {
      flex-basis: 90%;
      border: 1px solid $greyDark;
      border-radius: $radius;
      margin-bottom: 20px;
      background-color: $greyDark;

      img {
        width: 300px;
        height: 160px;
        border-radius: $radius;
      }

      .carInfo {
        padding: 20px;

        h3 {
          font-size: $sizeXS;
          color: $greyLight;
          text-align: right;
        }

        p {
          font-size: $sizeXXS;
          text-align: right;
          color: $greyLight;
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;
          span {
            display: inline-block;
            background-position: center;
            background-size: contain;
            width: 20px;
            height: 20px;
            content: "";
            background-color: $greyLight;
            border-radius: $radius;
            margin-right: 10px;
          }
        }

        p:nth-last-child(1) {
          font-weight: 700;
          color: $black;
          background-color: $greyLight;
          border-radius: $radius;
          justify-content: center;
        }

        .fuel {
          span {
            background-image: url("../../assets/svg/invert_colors_FILL0_wght400_GRAD0_opsz48.svg");
          }
        }
        .gear {
          span {
            background-image: url("../../assets/svg//usb_FILL0_wght400_GRAD0_opsz48.svg");
          }
        }
        .drive {
          span {
            background-image: url("../../assets/svg/settings_FILL0_wght400_GRAD0_opsz48.svg");
          }
        }
        .hp {
          span {
            background-image: url("../../assets/svg/bolt_FILL0_wght400_GRAD0_opsz48.svg");
          }
        }
        .price {
          span {
            background-image: url("../../assets/svg/payments_FILL0_wght400_GRAD0_opsz48.svg");
          }
        }
      }
    }
  }

  .reservation {
    width: 90%;
    margin: 20px auto;

    h3 {
      text-align: center;
    }
  }

  @media screen and (orientation: landscape) {
    .banner {
      height: auto;
    }
  }

  @media screen and (min-width: map-get($breakpoints, xSmall)) {
    .cars {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      .car {
        flex-basis: 49%;

        img {
          width: 320px;
          height: 180px;
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, small)) {
    .banner {
      height: 30vh;
    }

    .cars {
      .car {
        flex-basis: 45%;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, medium)) {
    .infoConetnt {
      .content1,
      .content2 {
        ul {
          li {
            font-size: $sizeXS;
          }
        }

        p {
          font-size: $sizeXS;
        }
      }
    }

    .cars {
      .car {
        flex-basis: 30%;

        img {
          // cursor: pointer;
        }

        .carInfo {
          h3 {
            font-size: $sizeS;
          }

          p {
            font-size: $sizeXS;
          }
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, large)) {
    .banner {
      background-image: url("../../assets/images/reservation-page/buick-classic-cars-3431909_1920.jpg");
    }

    .infoConetnt {
      .aButton {
        &:hover {
          color: $greyDarness;
          background-color: $greyLight;
        }
      }
    }

    .cars {
      .car {
        flex-basis: 24%;

        img {
          width: 300px;
          height: 160px;
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, xlarge)) {
    .infoConetnt {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .content1 {
        flex-basis: 100%;
      }

      .content2,
      .content3 {
        flex-basis: 48%;
      }
    }

    .cars {
      .car {
        flex-basis: 22%;

        img {
          width: 100%;
          height: 10vw;
        }

        .carInfo {
          h3 {
            font-size: $sizeL;
          }
        }
      }
    }
  }
}
.wrapperDark {
  .infoConetnt {
    .content1,
    .content2 {
      h3 {
        color: $lightLetters;
      }

      ul {

        li {
       color: $linksDarkHover;
        }
      }

      .aButton {
        border: 1px solid $linksDark;
        color: $lightLetters;
        background-color: $linksDark;

      }
    }
  }

  .cars {
    .car {
      border: 1px solid $linksDarkHover;
      background-color: $linksDarkHover;

      .carInfo {
        p {

          color: $greyLight;

          span {
            background-color: $greyLight;

          }
        }

        p:nth-last-child(1) {

          color: $lightLetters;
          background-color: $darkBackg;

        }
      }
    }
  }

  .reservation {

    h3 {
     color: $lightLetters;
    }
  }

  @media screen and (min-width: map-get($breakpoints, large)) {
    .infoConetnt {
      .aButton {
        &:hover {
          color: $greyDarness;
          background-color: $greyLight;
        }
      }
    }
  }
}