@import "../../../global-styles/common.scss";



.wrapper, .wrapperDark {
    min-height: 70vh;

    .image {
        background-image: url("../../../assets/images/offer/network-4478141_640.jpg");
        background-size: cover;
        width: 100%;
        height: 25vh;

        .mainTitle {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 20px;

            h1 {
                color: $greyLight
            }
        }
    }

    .content {
        width: 90%;
        margin: 30px auto 20px auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .boxOne,
        .boxTwo,
        .boxThree,
        .boxFour {
            margin-bottom: 20px;
            border: 1px solid $blueNormal;
            border-radius: $radius;
            padding: 10px;

            h3 {
                margin-bottom: 10px;
                font-size: $sizeXS;
                color: $blueNormal;
            }

            p {
                color: $greyDark;
                font-size: $sizeXXS;
                text-align: center;
            }
        }

        .boxOne {
            background-color: $greyDark;
            border-color: $greyDark;

            p {
                color: $greyLight;
                font-size: $sizeXS;
            }

        }

        a {
            flex-basis: 51%;
            @include standardButton;
            text-decoration: none;
            width: 120px;
            text-align: center;
        }
    }

    @media screen and (orientation:landscape) {
        .image {
            height: 30vh;
        }

        .content {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-around;

            .boxOne {
                flex-basis: 100%;
            }

            .boxTwo,
            .boxThree,
            .boxFour {
                flex-basis: 45%;
            }
        }
    }

    @media screen and (min-width:map-get($breakpoints, large)) {
        .image {
            height: 25vh;
            background-image: url("../../../assets/images/offer/network-4478141_1920.jpg");

            h1 {
                text-align: right;
                padding-top: 40px;
            }
        }

        .content {

            .boxOne,
            .boxTwo,
            .boxThree,
            .boxFour {
                h3 {
                    font-size: $sizeM;
                }

                p {
                    font-size: $sizeXS;
                }
            }


        }
    }

}

.wrapperDark {
    .content {
        .boxOne,
        .boxTwo,
        .boxThree,
        .boxFour {
            border: 1px solid $linksDarkHover;

            h3 {
                color: $linksDarkHover;
            }

            p {
                color: $lightLetters;

            }
        }

        .boxOne {
            background-color: $greyDark;
            border-color: $greyDark;

        }

    }

}