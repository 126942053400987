@import "../../global-styles/common.scss";

.blogWrapper, .blogWrapperDark {
    width: 80%;
    margin: 30px auto;
    min-height: 60vh;
    h2 {
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 30px;
    }

    .spinner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .postsWrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    @media screen and (orientation: landscape) {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }


    @media screen and (min-width:map-get($breakpoints, xlarge)) {
        width: 75%;
    }
}

.blogWrapperDark {
    .spinner {
        h4 {
            color: white;
        }
    }

    h2 {
        color: white;
    }
}