@import "../../global-styles/common.scss";
.wrapper, .wrapperDark {
  @include wrapper;
  margin-top: 30px;

  .inside {
    h1 {
      text-align: center;
      font-size: $sizeM;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      font-size: $sizeXXS;

      span {
        font-weight: 700;
      }
    }

    ul {
      margin-bottom: 20px;
      font-size: $sizeXXS;

      li {
        margin-bottom: 10px;
        margin-left: 20px;
        list-style: decimal;

        span {
          font-weight: 700;
        }
      }

      ul {
        margin-left: 20px;

        li {
          list-style: square;
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, medium)) {
    .inside {
      h1 {
        font-size: $sizeXL;
      }

      p,
      ul {
        font-size: $sizeXS;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, large)) {
    .inside {
      p,
      ul {
        font-size: $sizeS;
      }
    }
  }
}

.wrapperDark {
  color: white;

  a {
    color: $linksDarkHover;
  }
}