@import "../../global-styles/common.scss";

.formWrapper, .formWrapperDark {
  margin-top: 40px;
  // border: 1px solid $blueNormal;
  box-shadow: 10px 10px 20px darken($greyLight, 50%);
  border-radius: $radius;
  padding: 20px;

  .form {
    h4 {
      text-align: center;
      color: $blueNormal;
      margin-bottom: 10px;
    }

    form {
      .formOffer,
      .agreed {
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 10px;
          color: $greyDark;
          font-size: $sizeXXS;

          input {
            margin-right: 5px;
            width: 15px;
            height: 15px;
            border: none;
            cursor: pointer;
          }

          .rodo {
            background: none;
            border: none;
            padding: 0;
            color: $blueNormal;
          }
        }

                  span {
                    flex-basis: 100%;
                    color: red;
                    text-align: center;
                    font-weight: 700;
                    font-size: $sizeXXS;
                  }
      }

      .formContact {
        width: 100%;
        margin-top: 20px;

        div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          label {
            flex-basis: 30%;
            color: $greyDark;
            font-size: $sizeXXS;
          }

          input {
            flex-basis: 70%;
            padding: 4px;
            border: none;
            border: 1px solid $greyDark;
            border-radius: $radius;

            &:hover {
              background-color: $greyDark;
              color: $greyLight;
            }
          }

          textarea {
            flex-basis: 70%;
            height: 100px;
            border-radius: $radius;
            padding: 10px;
          }

          span {
            flex-basis: 100%;
            color: red;
            text-align: center;
            font-weight: 700;
            font-size: $sizeXXS;
          }
        }
      }

      .agreed {
        width: 100%;
        margin-top: 20px;
      }

      .buttons {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          @include standardButton;
        }
      }

      .formResponse {
        margin-top: 10px;

        p {
          text-align: center;
          font-weight: 700;
          color: green;
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, small)) {
    .form {
      form {
        .formContact {
          div:nth-child(3) {
            justify-content: flex-start;

            input {
              flex-basis: 30%;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, medium)) {
    width: 80%;
    margin: 40px auto 0 auto;
  }

  @media screen and (min-width: map-get($breakpoints, large)) {
    width: 80%;
    margin: 40px auto 0 auto;

    .form {
      form {
        .formOffer,
        .agreed {
          label {
            font-size: $sizeS;
            display: flex;
            align-items: center;

            input {
              margin-right: 10px;
              width: 20px;
              height: 20px;
            }
          }
        }

        .formContact {
          div {
            justify-content: flex-start;

            label {
              flex-basis: 20%;
              font-weight: 700;
              font-size: $sizeXS;
            }

            input {
              flex-basis: 60%;
              padding: 5px;

              &:hover {
                background-color: $greyLight;
                color: $greyDark;
              }
            }

            span {
              flex-basis: 100%;
              color: red;
              text-align: center;
              font-weight: 700;
              font-size: $sizeXXS;
            }
          }
        }

        .formRodo {
          label {
            display: block;

            .rodo {
              transition: $transition;
              &:hover {
                color: green;
              }
            }
          }
        }

        .buttons {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            @include standardButton;
          }
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, xlarge)) {
    width: 60%;
  }
}


.formWrapperDark {
  box-shadow: 10px 10px 20px lighten($linksDarkHover, 25%);
  .form {
    h4 {
      color: $lightLetters;
    }

  form {
    .formOffer,
    .agreed {
      label {
        color: $linksDarkHover;

        input {
          background-color:$linksDark ;
        }

        .rodo {
          color: $linksDark;
        }
      }
    }

    .formContact {
      div {

        label {
          color: $lightLetters;

        }

        input {
          border: 1px solid $linksDark;
          &:hover {
            background-color: $greyDark;
            color: $greyLight;
          }
        }

        textarea {
          flex-basis: 70%;
          height: 100px;
          border-radius: $radius;
          padding: 10px;
        }

      }
    }

  }
}
}