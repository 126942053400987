@import "../../global-styles/common.scss";

.wrapper, .wrapperDark {
    @include wrapper;
    margin-bottom: 40px;

    h1 {
        margin-top: 20px;
        margin-bottom: 40px;
        text-align: center;
        font-weight: 700;
        color: $blueNormal;
        font-size: $sizeXXL;

    }

    .infoSection {
        width: 95%;
        margin: 40px auto;
        display: flex;
        flex-wrap: wrap;

        a {
            flex-basis: 100%;
            text-decoration: none;
            border-radius: $radius;
            margin-bottom: 20px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;




            div {
                width: 100%;
                min-height: 100%;
                border-radius: $radius;


                h3 {
                    color: $greyLight;
                    text-align: center;
                    background-color: $black;
                    padding: 20px;
                    background-color: black;
                    opacity: 0.8;
                    border-radius: $radius;
                }

                p {
                    margin-top: 20px;
                    color: $greyLight;
                    text-align: center;
                    font-size: $sizeXXS;
                    opacity: 1;
                    padding: 20px;
                }

            }

            .buttons {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 40px;

                button {
                    @include smallButton;
                    width: 120px
                }
            }

        }

        a:nth-child(1) {
            background-image: url('../../assets/images/home-page/digital-marketing_640.jpg');
        }

        a:nth-child(2) {
            background-image: url('../../assets/images/home-page/bookcase-335849_640.jpg');
        }

        a:nth-child(3) {
            background-image: url('../../assets/images/home-page/teamwork-3213924_640.jpg');
        }
    }

    .offerSection {
        display: flex;
        flex-direction: column;
        width: 95%;
        margin: auto;

        h3 {
            font-size: $sizeL;
            text-align: center;
            margin-bottom: 20px;
            color: $blueNormal;
        }

        .carusel {
            border-radius: $radius;

            .imageOffer {
                height: 200px;
                background-image: url('../../assets/images/home-page/handshake_640.jpg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: $radius;

                h4 {
                    text-align: center;
                    padding-top: 20px;
                    color: white;
                    font-weight: 700;
                    font-size: $sizeL;
                    text-shadow: 2px 2px 0 black;
                }
            }

            .offerItem {
                height: 100%;
                background-color: $greyDark;
                padding: 20px;
                border-radius: $radius;

                .buttons {
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: space-between;


                    button {
                        @include smallButton;
                    }

                    p {
                        width: 100px;
                    }


                }

                a {
                    text-decoration: none;
                    height: 100%;



                    p {
                        font-size: $sizeXXS;
                        color: white;
                    }

                    p:nth-child(2) {
                        margin-top: 20px;
                        text-align: right;
                        font-weight: 700;
                        font-size: $sizeXS;
                        text-decoration: underline;
                        transition: $transition;

                        &:hover {
                            color: $black;
                        }
                    }
                }



            }
        }


    }

    .postsSection {
        margin-top: 40px;
        margin-bottom: 40px;

        h3 {
            text-align: center;
        }

        .postItems,
        .minPostItems {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .spinner {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }




    @media screen and (min-width:map-get($breakpoints, medium)) {
        .infoSection {
            a {
                div {
                    p {
                        font-size: $sizeXS;
                    }

                    .buttons {
                        justify-content: end;
                        width: 90%;
                    }
                }
            }
        }

        .offerSection {

            h3 {
                font-size: $sizeXL;

            }

            .carusel {


                .imageOffer {
                    height: 300px;
                    background-position-y: -200px;

                    h4 {
                        font-size: $sizeXL;
                    }
                }

                .offerItem {

                    a {

                        p {
                            font-size: $sizeXS;

                        }

                        p:nth-child(2) {

                            font-size: $sizeS;

                        }
                    }


                }
            }


        }

        .postsSection {
            h3 {
                font-size: $sizeXL;
            }

            .postItems,
            .minPostItems {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                flex-wrap: wrap;
            }


        }

    }

    @media screen and (min-width:map-get($breakpoints, large)) {


        .infoSection {
            justify-content: space-around;

            a {
                margin-bottom: 40px;

                div {
                    transition: $transition;

                    &:hover {
                        opacity: 0.95;
                    }
                }
            }

            a:nth-child(1) {
                background-image: url('../../assets/images/home-page/digital-marketing_1280.jpg');
            }

            a:nth-child(2) {
                background-image: url('../../assets/images/home-page/bookcase-335849_1280.jpg');
            }

            a:nth-child(3) {
                background-image: url('../../assets/images/home-page/teamwork-3213924_1280.jpg');
            }
        }

        .offerSection {


            h3 {
                font-size: $sizeXXL;
                text-align: left;

            }

            .carusel {

                display: flex;

                .imageOffer {
                    flex-basis: 30%;

                    background-position: center;

                    h4 {
                        font-size: $sizeXL;
                    }
                }

                .offerItem {
                    flex-basis: 70%;

                    a {

                        p {
                            font-size: $sizeS;

                        }

                    }

                }
            }

        }


        .postsSection {

            h3 {
                text-align: left;
                font-size: $sizeXXL;
            }

        }
    }

    @media screen and (min-width:map-get($breakpoints, xlarge)) {
        .infoSection {
            justify-content: space-around;
            flex-direction: row;

            a {

                flex-basis: 32%;

                div {

                    h3 {
                        font-size: $sizeL;

                    }

                    p {

                        font-size: $sizeS;

                    }

                }

            }
        }
    }
}

.wrapperDark {
    h1 {
        color: $lightLetters;
    }

    .offerSection {
    h3 {
        color: $lightLetters;
    }
    }
}