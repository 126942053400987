@import "../../global-styles/common.scss";

.footerWrapper {
  width: 100%;
  padding: 30px 0px;
  background-color: $greyDark;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .inside {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contact {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p,
      a {
        color: white;
        font-size: $sizeXXS;
        text-align: center;
      }

      a {
        font-weight: 700;
        text-decoration: none;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
          display: inline-block;
          height: 30px;

          svg {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            fill: $blueNormal;
          }
        }
      }
    }

    .media {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 200px;

      .map {
        width: 40px;
        height: 40px;

        svg {
          height: 100%;
          width: 100%;
          fill: rgb(184, 32, 32);
        }
      }

      .face {
        width: 40px;
        height: 40px;
        background-color: $greyLight;
        border-radius: $radius;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media screen and (orientation: landscape) {
    .inside {
      flex-direction: row;
      justify-content: space-between;

      .contact {
        align-items: flex-start;
        margin-bottom: 0px;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, medium)) {
    .inside {
      flex-direction: row;

      .contact {
        align-items: flex-start;
        margin-bottom: 0px;

        p,
        a {
          font-size: $sizeXS;
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, large)) {
    .inside {
      .contact {
        a {
          transition: $transition;

          &:hover {
            color: $blueNormal;
          }
        }
      }

      .media {
        .map {
          width: 50px;
          height: 50px;

          svg {
            transition: $transition;

            &:hover {
              fill: red;
            }
          }
        }

        .face {
          transition: $transition;

          &:hover {
            background-color: $blueNormal;
          }
        }
      }
    }
  }
}
