@import '../../global-styles/common.scss';

// @-webkit-keyframes contentShow {
//     0% {
//         opacity: 0;
//         top: 40%;
//     }

//     20% {
//         opacity: 0;
//         top: 40%;
//     }

//     40% {
//         opacity: 1;
//         top: 30%;
//     }

//     95% {
//         opacity: 1;
//         top: 30%;
//     }

//     100% {
//         opacity: 0;
//         top: 40%;
//     }

// }

// @keyframes contentShow {
//     0% {
//         opacity: 0;
//         top: 40%;
//     }

//     20% {
//         opacity: 0;
//         top: 40%;
//     }

//     40% {
//         opacity: 1;
//         top: 30%;
//     }

//     95% {
//         opacity: 1;
//         top: 30%;
//     }

//     100% {
//         opacity: 0;
//         top: 40%;
//     }

// }

.wrapper {
	position: relative;
	width: 100%;
	height: 35vh;
	overflow: hidden;

	.image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		transition: 3s;
		opacity: 0;

		.content {
			position: absolute;
			left: 5%;
			top: 40%;
			transition: 3s;

			p {
				color: $greyLight;
				font-weight: 700;
				font-size: $sizeXXL;
				background-color: $black;
				opacity: 0.85;
				padding: 5px 40px;
				border-radius: $radius;
			}
		}
	}

	a {
		position: absolute;
		bottom: 7%;
		right: 5%;
		text-decoration: none;
		color: $greyLight;
		font-weight: 700;
		font-size: $sizeXS;
		background-color: black;
		padding: 5px 15px;
		opacity: 0.8;
		border-radius: $radius;
	}

	// .dots {
	//     position: absolute;
	//     display: flex;
	//     justify-content: space-around;
	//     align-items: center;
	//     top: 90%;
	//     left: 50%;
	//     transform: translateX(-50%);
	//     width: 50%;
	//     height: 10%;

	//     .dot {
	//         width: 10px;
	//         border: 2px solid $firstColor;
	//         height: 10px;
	//         background-color: $secondColor;
	//         border-radius: $box-radius;
	//         transition: 3s;
	//         cursor: pointer;
	//     }
	// }
	@media screen and (orientation: landscape) {
		height: 45vh;
	}

	@media screen and (min-width: map-get($breakpoints, medium)) {
		.image {
			.content {
				p {
					font-size: $sizeXXXL;
				}
			}
		}

		a {
			font-size: $sizeS;
		}

		// .dots {
		//     width: 30%;
		//     height: 10%;

		//     .dot {
		//         width: 15px;
		//         height: 15px;
		//     }
		// }
	}

	@media screen and (min-width: map-get($breakpoints, large)) {
		height: 35vh;

		.image {
			.content {
				p {
					font-size: $sizeXXXL * 2;
				}
			}
		}

		a {
			bottom: 7%;
			font-size: $sizeL;
			transition: $transition;

			&:hover {
				background-color: white;
				color: black;
			}
		}
	}

	@media screen and (min-width: map-get($breakpoints, xlarge)) {
		.image {
			.content {
				p {
					font-size: $sizeXXXL * 2.3;
				}
			}
		}

		.dots {
			width: 20%;
		}
	}
}
