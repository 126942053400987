@import "../../global-styles/common.scss";

.wrapper {
    width: 100%;
    min-height: 40vh;

    .banner {
        width: 100%;
        height: 30vh;
        background-image: url("../../assets/images/benefits-page/money-2696228_640.jpg");
        background-size: cover;
        background-position: center;

        h1 {
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: $greyLight;
            text-transform: uppercase;
        }
    }

    .infoConetnt {
        width: 90%;
        margin: 20px auto;

        .content1,
        .content2 {
            border: 1px solid $greyDarness;
            border-radius: $radius;
            padding: 15px;
            margin-bottom: 30px;
            background-color: $greyDarness;

            h3 {
                color: $blueNormal;
                margin-bottom: 10px;
                color: $greyLight;
            }

            ul {
                list-style: square;

                li {
                    font-size: $sizeXXS;
                    margin-left: 20px;
                    color: $greyLight;

                }
            }
        }

        .content2 {
            border-color: $greyDark;
            background-color: $greyDark;
        }


    }

    @media screen and (orientation: landscape) {
        .banner {
            height: auto;
        }
    }

    @media screen and (min-width:map-get($breakpoints, small)) {
        .banner {
            height: 30vh;
        }
    }

    @media screen and (min-width:map-get($breakpoints, medium)) {
        .infoConetnt {
            position: relative;
            margin: 40px auto;


            .content1,
            .content2 {
                padding: 30px;
                width: 60%;

                ul {

                    li {
                        font-size: $sizeXS;

                    }
                }
            }
            .content2 {
                margin-left: 40%;
                margin-top: -50px;
            }
        }
    }

    @media screen and (min-width:map-get($breakpoints, large)) {
        .banner {
            background-image: url("../../assets/images/benefits-page/money-2696228_1920.jpg");
        }
    }

    @media screen and (min-width:map-get($breakpoints, xlarge)) {
        .infoConetnt {


            .content1,
            .content2 {

                ul {

                    li {
                        font-size: $sizeS;

                    }
                }
            }

        }
    }
}