@import "../../global-styles/common.scss";

.modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 120;
  backdrop-filter: blur(2px);

  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    z-index: 20;
    border: none;
    background-color: white;
    color: black;
    border-radius: $radius;
    width: 85%;
    height: 80%;
  }
  @media screen and (orientation: landscape) {
    .wrapper {
      width: 55%;
      height: auto;
    }
  }

  @media screen and (min-width: map-get($breakpoints, small)) {
    .wrapper {
      width: 80%;
      height: auto;
    }
  }

  @media screen and (min-width: map-get($breakpoints, medium)) {
    .wrapper {
      top: 45%;
      width: 600px;
      height: auto;
    }
  }

  @media screen and (min-width: map-get($breakpoints, large)) {
    .wrapper {
      width: 550px;
      height: auto;
    }
  }
}
