@import "../../global-styles/common.scss";

.switchWrapper, .switchWrapperDark {
    position: relative;
    width: 20px;
    height: 40px;
    border: 1px solid $blueNormal;
    border-radius: 20px;
    background-color: $greyDarness;

    .switchContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .dark, .light {
            width: 100%;
            height: 50%;
            border-radius: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

        }

        .dark {
            background-image: url('../../assets/svg/dark_mode.svg');
            background-color: $greyLight;
        }

        .light {
            background-image: url('../../assets/svg/light_mode.svg');
            background-color: yellow;
        }
    }

    .switchDot{
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 50%;
        border-radius: 20px;
        border: 1px solid $greyLight;
        background-color: $greyDarness;
        transition: $transition;
    }

    @media screen and (min-width: map-get($breakpoints, large)) {
        width: 25px;
        height: 50px;
        cursor: pointer;

    }
}

.switchWrapperDark {
    border: 1px solid $greyLight;
    background-color: $greyLight;
}