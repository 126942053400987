@import "../../global-styles/common.scss";

.wrapper, .wrapperDark {
    width: 100%;
    min-height: 40vh;

    .banner {
        width: 100%;
        height: 30vh;
        background-image: url("../../assets/images//law-page/law-4617873_640.jpg");
        background-size: cover;
        background-position: center;

        h1 {
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            color: $greyLight;
            text-transform: uppercase;
        }
    }

    .infoConetnt {
        width: 90%;
        margin: 20px auto;

        .content1,
        .content2,
        .content3 {
            margin-bottom: 30px;

            h3 {
                color: $blueNormal;
                margin-bottom: 10px;
            }

            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                a {
                    font-size: $sizeXXS;
                    margin-bottom: 10px;
                    margin-left: 20px;
                }
            }
        }


    }

    @media screen and (orientation: landscape) {
        .banner {
            height: auto;
        }
    }

    @media screen and (min-width:map-get($breakpoints, small)) {
        .banner {
            height: 30vh;
        }
    }



    @media screen and (min-width:map-get($breakpoints, large)) {
        .banner {
            background-image: url("../../assets/images/law-page/law-4617873_1920.jpg");
        }
    }

    @media screen and (min-width:map-get($breakpoints, xlarge)) {
        .infoConetnt {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .content1 {
                flex-basis: 100%;
            }

            .content2,
            .content3 {
                flex-basis: 48%;
            }



        }
    }
}

.wrapperDark {
    .infoConetnt {
        .content1,
        .content2,
        .content3 {

            h3 {
                color: $lightLetters;
            }

            ul {

                a {
                   color: $linksDarkHover;
                }
            }
        }


    }
}