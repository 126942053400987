@import "../../global-styles/common.scss";

.postWrapper,
.postWrapperDark {
    width: 100%;
    margin-bottom: 30px;

    .inside {
        width: 95%;
        margin: 0 auto;
        border: 1px solid $blueNormal;
        border-radius: $radius;

        img {
            width: 100%;
            height: 150px;
            border-radius: $radius;
        }

        .contents {
            padding: 15px;

            h4 {
                text-align: center;
                margin-bottom: 20px;
            }

            p {
                font-size: $sizeXXS;
                text-align: center;
                margin-bottom: 10px;
            }

            a {
                display: block;
                color: $blueNormal;
                font-size: $sizeXXS;
                font-weight: 700;
                text-align: right;

            }

            button {
                @include smallButton;
            }
        }

        .signature {
            padding: 15px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-size: $sizeXXS;
                font-weight: 700;
            }
        }
    }

    @media screen and (orientation: landscape) {
        width: 75%;

    }

    @media screen and (min-width:map-get($breakpoints, small)) {
        width: 75%;

        .inside {
            img {

                height: 200px;

            }
        }
    }

    @media screen and (min-width:map-get($breakpoints, medium)) {
        width: 48%;

        .inside {
            min-height: 470px;

            img {

                height: 150px;
            }

            .contents {

                p {
                    font-size: $sizeXS;

                }

                a {
                    color: $blueNormal;
                    font-size: $sizeXS;
                    font-weight: 700;

                }
            }
        }
    }

    @media screen and (min-width:map-get($breakpoints, large)) {


        .inside {
            .contents {

                a {
                    transition: $transition;

                    &:hover {
                        color: $greyDark;
                    }

                }
            }
        }
    }

    @media screen and (min-width:map-get($breakpoints, xlarge)) {
        width: 33%;

        .inside {

            min-height: 480px;

            img {
                height: 180px;
            }
        }
    }
}

.postWrapperDark {
    .inside {
        border: 1px solid $linksDarkHover;

        .contents {

            h4,
            p {
                color: $lightLetters;
            }

            ;

            a {
                color: $linksDark;
            }

            button {
                @include smallButton;
            }
        }

        .signature {
            p {
                color: $lightLetters;
            }
        }
    }

    @media screen and (min-width:map-get($breakpoints, large)) {


        .inside {
            .contents {

                a {
                    transition: $transition;

                    &:hover {
                        color: $linksDarkHover;
                    }

                }
            }
        }
    }
}