@import "../../global-styles/common.scss";

.navWrapper, .navWrapperDark {
  .switchTheme {
    position: absolute;
    right: 30px;
    top: 70px
  }

  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  .navLink {
    width: 210px;
    height: auto;
    object-fit: cover;

    .navLogo {
      width: 100%;
      height: 100%;
      margin-left: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .navBurger {
    flex-basis: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      height: 40px;
      width: 35px;
      fill: $blueNormal;
      margin-right: 20px;
      margin-top: -50px;
    }
  }

  .navLinksClose,
  .navLinksOpen {
    display: none;
    position: absolute;
    top: 135px;
    left: 0;
    width: 100%;
    height: 330px;
    background-color: $greyDark;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 0.5s;
    opacity: 0;

    a {
      text-decoration: none;
      margin-bottom: 10px;
      color: $greyLight;
      font-weight: 700;
    }

    a:nth-last-child(1) {
      background-color: red;
      padding: 5px 10px;
      border-radius: $radius;
      color: $greyLight;
      font-size: $sizeXXS;
    }
  }

  .navLinksOpen {
    opacity: 0.95;
    display: flex;
  }

  @media screen and (orientation: landscape) {
    .navLinksClose,
    .navLinksOpen {
      height: 130px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      a {
        flex-basis: 20%;
        margin-bottom: 0px;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, medium)) {
    .navLinksClose,
    .navLinksOpen {
      height: 290px;

      a {
        font-size: $sizeS;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, large)) {
    .switchTheme {
      right: 1%;
      top: 38px;
    }

    .navBurger {
      display: none;
    }

    .navLinksClose,
    .navLinksOpen {
      flex-basis: 90%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 0;
      opacity: 1;
      background-color: transparent;
      height: 90px;

      a {
        flex-basis: 10%;
        color: $greyDarness;
        text-align: center;
        margin-left: 20px;

        &:hover {
          color: $blueNormal;
          transform: scale(1.1);
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoints, xlarge)) {

    .navLinksClose,
    .navLinksOpen {
      a {
        flex-basis: 8%;
      }
    }
  }
}


.navWrapperDark {
  background-color: $darkBackg;

  .navLink {

    .navLogo {


      img {

        filter: invert(100%);
      }
    }
  }

  .navBurger {
    svg {
      fill: $lightIcon;

    }
  }

  @media screen and (min-width: map-get($breakpoints, large)) {
    .navLinksClose,
    .navLinksOpen {
      a {
        color: $lightLetters;

        &:hover {
          color: $lightLettersHover;
        }
      }
    }
  }
}